import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlDiary, httpOption, httpOptionFormData, UrlUpload} from '../settings/config';
import {EVENT_URLS} from '../settings/route_urls';
import {UserObject} from '../models/data.models';
import {UserService} from './user.service';
import {DGKLanguageService} from './language.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {
  userInfo: UserObject;
  languageId: number;
  languageCode: String;

  changeSubject: Subject<any> = new Subject<any>();

  constructor(private httpClient: HttpClient, private userService: UserService, private languageService: DGKLanguageService) {
    this.userInfo = this.userService.userInfo;
    this.languageId = this.languageService.language_id;

    this.userService.UserSubject.subscribe((p: UserObject) => {
      this.userInfo = p;
    })
    this.languageService.languageChange.subscribe((p: string) => {
      this.languageCode = p;
    });
  }

  createDiary(data) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/create', data, httpOption(this.userInfo.access_token, this.languageId));
  }

  editDiary(data) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/edit', data, httpOption(this.userInfo.access_token, this.languageId));
  }


  findFarms() {
    return this.httpClient.get(UrlDiary + 'data/findfarms', httpOption(this.userInfo.access_token, this.languageId));
  }

  findDiaryInfo(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/info', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findStaffs() {
    return this.httpClient.get(UrlDiary + 'data/findstaffs', httpOption(this.userInfo.access_token, this.languageId));
  }

  

  findProducts() {
    return this.httpClient.get(UrlDiary + 'data/findproducts', httpOption(this.userInfo.access_token, this.languageId));
  }

  filterDiaries(typeId: number) {
    return this.httpClient.get(UrlDiary + 'diaryfarm/filter?type_id=' + typeId, httpOption(this.userInfo.access_token, this.languageId));
  }


  findAllStaffs(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/showallstaffs', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  
  findDiaryProducts(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/diaryproducts', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findDiaryDetailInfo(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/detailinfos', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  addStaffToDiary(diary_id: number, staff_id: number, is_add: boolean) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/addstaff', {
      'diary_id': diary_id,
      'staff_id': staff_id,
      'is_add': is_add
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  
  addProductToDiary(diary_id: number, product_id: number, is_add: boolean) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/addproduct', {
      'diary_id': diary_id,
      'product_id': product_id,
      'is_add': is_add
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findDiartStaffs(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/finddiarystaffs', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findTaskActivities(task_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/getactivity', {
      'task_id': task_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findDiaryTaskInfo(task_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/taskinfo', {
      'task_id': task_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findDiaryTaskImages(task_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/task/images', {
      'task_id': task_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  finishTask(task_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/finishtask', {
      'task_id': task_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findDiaryTaskActivityImages(activity_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/task/activityimages', {
      'activity_id': activity_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  uploadImage(task_id, activity_id, image, extension, mimetype) {

    let formData = new FormData();

    if (image) {
      formData.append('images', image, image.name);
    }

    formData.append('task_id', task_id);
    formData.append('minetype', mimetype);
    formData.append('extension', extension);
    formData.append('activity_id', activity_id);
    return this.httpClient.post(UrlDiary + 'diaryfarm/task/uploadimage', formData, httpOption(this.userInfo.access_token, this.languageId));
  }

  addDiaryTask(data) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/addtask', data, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateDiaryTask(task_id, staff_id, name, notes) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/updatetask', {
      "task_id": Number(task_id),
      "staff_id": Number(staff_id),
      "name": name,
      "notes": notes
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findTaskInDay(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/taskinday', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findTaskNext(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/tasknext', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findTaskInPast(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/taskpast', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findTaskAllDay(diary_id: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/taskallday', {
      'diary_id': diary_id
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  sendEdit(task_id) {
    this.changeSubject.next({
      "action": 1,
      "task_id": task_id
    })
  }

  sendChangeStaffAdd() {
    this.changeSubject.next({
      "action": 5
    })
  }

  addDiaryTaskActivity(data) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/createactivity', data, httpOption(this.userInfo.access_token, this.languageId));
  }


  findDiaryByProducts(diaryTypeId: number, productId: number) {
    return this.httpClient.post(UrlDiary + 'diaryfarm/filterbyproduct', {
      "diary_type": Number(diaryTypeId),
      "product_id": Number(productId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

}
