import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserObject } from '../models/data.models';
import { httpOption, UrlAPI } from '../settings/config';
import { DGKLanguageService } from './language.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AccessoriesService {

    userInfo: UserObject;
    languageId: number;
    languageCode: string;

    constructor(private httpClient: HttpClient, private userService: UserService, private languageService: DGKLanguageService) {
        this.userInfo = this.userService.userInfo;
        this.languageId = this.languageService.language_id;

        this.userService.UserSubject.subscribe((p: UserObject) => {
            this.userInfo = p;
        })
        this.languageService.languageChange.subscribe((p: string) => {
            this.languageCode = p;
        });

    }

    getAccessoriesTypes(groupId) {
        return this.httpClient.post(UrlAPI + 'accessories/types', {
            'group_id': Number(groupId),
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    createAccessories(data) {
        return this.httpClient.post(UrlAPI + 'accessories/create', data, httpOption(this.userInfo.access_token, this.languageId));
    }


    updateAccessories(data) {
        return this.httpClient.post(UrlAPI + 'accessories/edit', data, httpOption(this.userInfo.access_token, this.languageId));
    }

    findAccessories(groupId) {
        return this.httpClient.post(UrlAPI + 'accessories/filter', {
            'group_id': Number(groupId),
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    checkStampSell(code, accessories_id) {
        return this.httpClient.post(UrlAPI + 'accessories/checkproducebatchsell', {
            'code': code,
            'accessories_id': Number(accessories_id)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    findAccessoriesWarehouse(statusId, accessoriesId) {
        return this.httpClient.post(UrlAPI + 'accessories/warehouse', {
            'status_id': Number(statusId),
            'accessories_id': Number(accessoriesId)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    createAccessoriesDocumentLabel(accessoriesId, pages, notes: string) {
        return this.httpClient.post(UrlAPI + 'accessories/warehouse/create', {
            'pages': pages,
            'accessories_id': Number(accessoriesId),
            'notes': notes
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    createAccessoriesDocument(accessoriesId, quantities, notes) {
        return this.httpClient.post(UrlAPI + 'accessories/warehouse/createdocument', {
            'quantities': Number(quantities),
            'notes': notes,
            'accessories_id': Number(accessoriesId)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    findAccessoriesLabelTraceabilities() {
        return this.httpClient.get(UrlAPI + 'accessories/labels/traceabilities', httpOption(this.userInfo.access_token, this.languageId));
    }


    reportInventoryStampByPattern() {
        return this.httpClient.get(UrlAPI + 'stamp/pattern/report', httpOption(this.userInfo.access_token, this.languageId));
    }
}